import BaseController from "decor/base_controller";

export default class MessageItemController extends BaseController {
  public get sentAt(): number {
    return Number(this.data.get("sentAt"));
  }

  public get senderUid(): string {
    return this.data.get("senderUid")!;
  }

  public get messageType(): string {
    return this.data.get("messageType") || "Message";
  }

  public get eventType(): string | null {
    return this.data.get("eventType");
  }

  public get uid(): string {
    return this.data.get("messageUid")!;
  }
}
