import PurchaseConfiguratorVariantAbstract from "mercor/purchase_configurator/purchase_configurator_variant_abstract";
import {
  calculateTotalPriceForDisplay,
  calculateTotalPriceNumber,
} from "lib/price_calculations";
import PurchaseConfiguratorQuantityExtraInfoController from "./extra_info_controller";
import { getAppConfiguration } from "lib/app_configuration";
import { Mercor__PurchaseConfigurator__ExtraInfoControllerIdentifier } from "controllers/identifiers";

interface DataAttributes {
  fallbackDisplay: string;
  extraChargeMultiplier: string | number;
  priceMultiplier: string | number;
  quantityType: "float" | "integer";
  sizeExcludedFromCalculation?: boolean;
  currency: string;
  variantUid: number;
}

abstract class BasePurchaseConfiguratorQuantityCalculatorController extends PurchaseConfiguratorVariantAbstract {
  public readonly fieldsetTarget!: HTMLFieldSetElement;
  public readonly quantityTarget!: HTMLInputElement;
  public readonly quantityErrorTarget!: HTMLParagraphElement;
  public readonly sizeTarget!: HTMLInputElement | HTMLSelectElement;
  public readonly hasSizeTarget!: boolean;
  public readonly sizeErrorTarget!: HTMLParagraphElement;
  public readonly hasSizeErrorTarget!: boolean;
  public readonly totalPriceTarget!: HTMLSpanElement;
}

export default class PurchaseConfiguratorQuantityCalculatorController extends (PurchaseConfiguratorVariantAbstract as typeof BasePurchaseConfiguratorQuantityCalculatorController) {
  public static outlets = [
    Mercor__PurchaseConfigurator__ExtraInfoControllerIdentifier,
  ];
  private declare readonly mercorPurchaseConfiguratorExtraInfoOutlets: PurchaseConfiguratorQuantityExtraInfoController[];
  private get extraInfoControllers() {
    return this.mercorPurchaseConfiguratorExtraInfoOutlets;
  }

  public static targets = [
    "fieldset",
    "quantity",
    "quantityError",
    "size",
    "sizeError",
    "totalPrice",
  ];
  protected quantityData: any;

  public onInitialize() {
    this.onConnect(() => {
      this.handleFieldsetInput();
    });

    this.quantityData =
      this.getRequiredDataAttrAsJSON<DataAttributes>("quantityData");
    return super.onInitialize();
  }

  public set isCurrent(isCurrent: boolean) {
    super.isCurrent = isCurrent;
    if (isCurrent) {
      this.enableInputs();
    } else {
      this.disableInputs();
    }
  }

  public get hasSize() {
    return !!this.hasSizeTarget;
  }

  public handleFieldsetInput() {
    // Hard code something in case the data attr parsing goes belly-up
    let fallbackDisplay = "-";

    try {
      this.quantityData =
        this.getRequiredDataAttrAsJSON<DataAttributes>("quantityData");
      const {
        currency,
        extraChargeMultiplier,
        priceMultiplier,
        quantityType,
        sizeExcludedFromCalculation,
        sizeUnitConversionFactor,
        ...other
      } = this.quantityData;

      // We can now get the real fallback display to use
      fallbackDisplay = other.fallbackDisplay;

      // Grab the actual values from the UI
      const quantity = this.quantityTarget.value;
      const size = this.hasSizeTarget ? this.sizeTarget.value : undefined;

      // Generate and set the total price
      const opts = {
        currency,
        extraChargeMultiplier,
        locale: getAppConfiguration("locale"),
        priceMultiplier,
        quantity,
        quantityType,
        size,
        sizeExcludedFromCalculation,
        sizeUnitConversionFactor,
      };
      const totalPriceNumber = calculateTotalPriceNumber(opts);
      const totalPrice = calculateTotalPriceForDisplay(opts, totalPriceNumber);

      this.totalPrice = totalPrice !== null ? totalPrice : fallbackDisplay;
      this.updateTotalPriceNumber(totalPriceNumber, size);

      // Update the info sections, if any
      this.extraInfoControllers.forEach((c) =>
        c.updateValue({ quantity, quantityType, size, fallbackDisplay }),
      );
    } catch (e) {
      // Ru-roh, something's gone wrong when parsing the attributes
      // TODO handle this error: show the user somehow
      console.error("Error on purcon input", e);
      this.totalPrice = fallbackDisplay;
    }
  }

  protected updateTotalPriceNumber(price: number, size: string | undefined) {
    // This can be overwritten by whatever class wants to get updated on the est. price
    return;
  }

  protected set totalPrice(price: string) {
    this.totalPriceTarget.textContent = price;
  }

  private enableInputs() {
    // Rails-UJS will support the automatic removal of input data from within disabled fieldsets soon.
    // For now we need to disable inner inputs manually.
    // We don't care about disabling through the MDC instance, since the inputs will be hidden anyway, so styling changes don't matter.
    this.fieldsetTarget.removeAttribute("disabled");

    // TODO we can remove this hack once Rails has support for not serializing form inputs inside disabled fieldsets
    // https://github.com/rails/rails/pull/36764
    this.quantityTarget.removeAttribute("disabled");
    if (this.hasSizeTarget) {
      this.sizeTarget.removeAttribute("disabled");
    }
  }

  private disableInputs() {
    // Rails-UJS will support the automatic removal of input data from within disabled fieldsets soon.
    // For now we need to disable inner inputs manually.
    // We don't care about disabling through the MDC instance, since the inputs will be hidden anyway, so styling changes don't matter.
    this.fieldsetTarget.setAttribute("disabled", "disabled");

    // TODO we can remove this hack once Rails has support for not serializing form inputs inside disabled fieldsets
    // https://github.com/rails/rails/pull/36764
    this.quantityTarget.setAttribute("disabled", "disabled");
    if (this.hasSizeTarget) {
      this.sizeTarget.setAttribute("disabled", "disabled");
    }
  }
}
