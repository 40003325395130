import BaseController from "decor/base_controller";
import { showModal } from "lib/overlay/modal";
import { showNotification } from "lib/overlay/notification";
import DropdownController from "decor/dropdown_controller";
import { Decor__DropdownControllerIdentifier } from "controllers/identifiers";

type RefreshPopupMode = "inactive" | "countdown" | "show_immediately";

const ACTIVE_CLASS =
  "shopping__product-serviceability-dropdown__button--active";
const DROPDOWN_MINUTES_BEFORE_RUN = 60;
const TIMED_TOASTER_MINUTES_BEFORE_RUN = 30;
const TIMED_TOASTER_DISPLAY_UNTIL_IN_MINUTES = 11;
const DISMISSABLE_TOASTER_MINUTES_BEFORE_RUN = 10;
const DISMISSABLE_TOASTER_DISPLAY_UNTIL_IN_MINUTES = 1;
const REFRESH_POPUP_SHOW_IMMEDIATELY_DELAY = 2;

export default class ServiceabilityDropdownController extends BaseController {
  // Targets
  public static targets = ["dropdownButton"];
  private declare readonly dropdownButtonTarget: HTMLElement;

  // Outlets
  public static outlets = [Decor__DropdownControllerIdentifier];
  private declare readonly decorDropdownOutlet: DropdownController;
  public get dropdownController() {
    return this.decorDropdownOutlet;
  }

  public onInitialize() {
    this.onConnect(() => {
      const timeToCutOff = this.timeToCutOff;
      const refreshPopupMode = this.refreshPopupMode;

      if (timeToCutOff !== null && timeToCutOff > 0) {
        const timeToActivateDropdown =
          timeToCutOff - this.secondsBeforeRun(DROPDOWN_MINUTES_BEFORE_RUN);
        const timeToActivateTimedToaster =
          timeToCutOff -
          this.secondsBeforeRun(TIMED_TOASTER_MINUTES_BEFORE_RUN);
        const timeToActivateDismissableToaster =
          timeToCutOff -
          this.secondsBeforeRun(DISMISSABLE_TOASTER_MINUTES_BEFORE_RUN);
        const activateTimedToasterTimerId = this.activateTimedToaster(
          timeToCutOff,
          this.calculateTimeout(timeToActivateTimedToaster),
        );
        const activateDismissableToasterTimerId =
          this.activateDismissableToaster(
            timeToCutOff,
            this.calculateTimeout(timeToActivateDismissableToaster),
          );

        const activateDropdownTimerId = this.activateDropdown(
          this.calculateTimeout(timeToActivateDropdown),
        );
        const deactivateDropdownTimerId = this.deactivateDropdown(
          this.calculateTimeout(timeToCutOff),
        );

        const popupTimerId = this.initialiseRefreshDialog(
          refreshPopupMode,
          timeToCutOff,
        );

        return () => {
          clearTimeout(activateDropdownTimerId);
          clearTimeout(deactivateDropdownTimerId);
          clearTimeout(activateTimedToasterTimerId);
          clearTimeout(activateDismissableToasterTimerId);
          clearTimeout(popupTimerId);
        };
      }
    });

    return super.onInitialize();
  }

  public toggle(event: Event) {
    this.dropdownController.toggle(event);
  }

  public hideOnClickOutside(event?: Event) {
    if (event && this.dropdownButtonTarget.contains(event.target as Node)) {
      return;
    }
    this.dropdownController.hideOnClickOutside(event);
  }

  public hide() {
    this.dropdownController.hide();
  }

  private initialiseRefreshDialog(
    refreshPopupMode: RefreshPopupMode,
    timeToCutOff: number,
  ) {
    if (refreshPopupMode === "inactive") {
      return;
    }

    const timeoutDelay =
      refreshPopupMode === "countdown"
        ? timeToCutOff
        : REFRESH_POPUP_SHOW_IMMEDIATELY_DELAY;
    return this.countdownRefreshPopup(this.calculateTimeout(timeoutDelay));
  }

  private activateDropdown = (timeout: number) => {
    if (timeout <= 0) {
      this.addActiveClass();
      return;
    }
    return window.setTimeout(this.addActiveClass, timeout);
  };

  private deactivateDropdown = (timeout: number) => {
    if (timeout <= 0) {
      return;
    }
    return window.setTimeout(this.removeActiveClass, timeout);
  };

  private activateTimedToaster = (timeToCutOff: number, timeout: number) => {
    if (timeout > 0) {
      return window.setTimeout(this.showTimedToaster, timeout);
    }
    if (
      timeToCutOff >
      this.secondsBeforeRun(TIMED_TOASTER_DISPLAY_UNTIL_IN_MINUTES)
    ) {
      return window.setTimeout(this.showTimedToaster, 2000);
    }
  };

  private activateDismissableToaster = (
    timeToCutOff: number,
    timeout: number,
  ) => {
    if (timeout > 0) {
      return window.setTimeout(this.showDismissableToaster, timeout);
    }
    if (
      timeToCutOff >
      this.secondsBeforeRun(DISMISSABLE_TOASTER_DISPLAY_UNTIL_IN_MINUTES)
    ) {
      return window.setTimeout(this.showDismissableToaster, 2000);
    }
  };

  private countdownRefreshPopup = (timeout: number) => {
    if (timeout <= 0) {
      return;
    }
    return window.setTimeout(this.showRefreshPopup, timeout);
  };

  private showTimedToaster = () => {
    showNotification({
      contentHref: "/marketplace/reminders/hurry-up",
      timeout: 30000,
    });
  };

  private showDismissableToaster() {
    showNotification({
      contentHref: "/marketplace/reminders/last-chance",
      timeout: Infinity,
    });
  }

  private calculateTimeout(timeoutInSeconds: number) {
    return timeoutInSeconds * 1000;
  }

  private secondsBeforeRun(minutesBeforeRun: number) {
    return minutesBeforeRun * 60;
  }

  private addActiveClass = () => {
    this.dropdownButtonTarget.classList.add(ACTIVE_CLASS);
  };

  private removeActiveClass = () => {
    this.dropdownButtonTarget.classList.remove(ACTIVE_CLASS);
  };

  private showRefreshPopup = () => {
    const ids = this.data.get("cartUid");
    const currentPage = this.data.get("currentPage");
    const href = `/marketplace/reminders/refresh-page?cart_uid=${ids}&current_page=${currentPage}`;
    showModal({
      contentHref: href,
    });
  };

  private get timeToCutOff(): number | null {
    const timeToCutOffStr = this.data.get("timeToCutOff");
    return timeToCutOffStr !== null ? parseInt(timeToCutOffStr, 10) : null;
  }

  private get refreshPopupMode(): RefreshPopupMode {
    const refreshPopupMode = this.data.get("refreshPopupMode");
    switch (refreshPopupMode) {
      case "show_immediately":
      case "countdown":
        return refreshPopupMode;
      default:
        return "inactive";
    }
  }
}
