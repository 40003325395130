import BaseController from "decor/base_controller";
import FormFieldController from "decor/forms/form_field_controller";
import PurchaseConfiguratorExtraInfoController from "mercor/purchase_configurator/extra_info_controller";
import {
  calculateTotalPriceForDisplay,
  calculateTotalPriceNumber,
  isNullOrBigZero,
  parseVariantSize,
} from "lib/price_calculations";
import { getAppConfiguration } from "lib/app_configuration";
import { markAsSafeHTML, safelySetInnerHTML } from "lib/util/safe_html";
import { Mercor__PurchaseConfigurator__ExtraInfoControllerIdentifier } from "controllers/identifiers";

type ProductPriceChangedDetail = {
  totalPriceNumber: number;
  sku: string;
  variant: string;
  size: string | undefined;
  parsedSize: Big.Big | undefined;
  validSize: boolean;
  hasSize: boolean;
};

export type ProductPriceChangedEvent = CustomEvent<ProductPriceChangedDetail>;

interface VariantDataAttributes {
  sku: string;
  variantUid: string;
  fallbackDisplay: string;
  extraChargeMultiplier: string | number;
  priceMultiplier: string | number;
  quantityType: "float" | "integer";
  sizeUnitConversionFactor?: string | number;
  sizeExcludedFromCalculation?: boolean;
  currency: string;
}

export default class OrderGuideRowController extends BaseController {
  public static targets = [
    "shortDescription",
    "sku",
    "variantSelector",
    "pricePerUom",
    "rowTotalPrice",
  ];

  private declare readonly shortDescriptionTarget: HTMLTableCellElement;
  private declare readonly skuTarget: HTMLTableCellElement;
  private declare readonly variantSelectorTarget: HTMLSelectElement;
  private declare readonly pricePerUomTargets: HTMLDivElement[];
  private declare readonly rowTotalPriceTarget: HTMLDivElement;

  public static outlets = [
    Mercor__PurchaseConfigurator__ExtraInfoControllerIdentifier,
  ];
  private declare readonly mercorPurchaseConfiguratorExtraInfoOutlets: PurchaseConfiguratorExtraInfoController[];
  private get extraInfoControllers() {
    return this.mercorPurchaseConfiguratorExtraInfoOutlets;
  }

  protected declare quantityData: { [key: string]: VariantDataAttributes };

  private get formFieldControllers(): FormFieldController[] {
    return this.getSpecificChildControllers<FormFieldController>(
      FormFieldController,
    );
  }

  onInitialize() {
    this.onConnect(() => {
      this.displaySelectedVariant(this.variantSelectorTarget.value);
    });

    this.quantityData = this.getRequiredDataAttrAsJSON<{
      [key: string]: VariantDataAttributes;
    }>("variantsQuantityData");

    return super.onInitialize();
  }

  private isCurrentConfigurationValid(hasRequiredSize: boolean) {
    const size = this.currentSizeField;
    const req = hasRequiredSize && this.currentQuantityField!.valid;
    return size ? size.valid && req : req;
  }

  public get selectedVariant() {
    return this.variantSelectorTarget.value;
  }

  public handleVariantChanged(evt: Event) {
    this.displaySelectedVariant(this.selectedVariant);
    this.updateCalculations();
  }

  public handlePortionSizeChanged(evt: Event) {
    const size = parseVariantSize(this.currentSize);
    if (size && size.gt(1000000)) {
      this.currentSizeField!.value = "1000000";
    }
    this.updateCalculations();
  }

  public handleQuantityChanged(evt: Event) {
    const qty = parseVariantSize(this.currentQuantity);
    if (qty && qty.gt(1000000)) {
      this.currentQuantityField!.value = "1000000";
    }
    this.updateCalculations();
  }

  private updateCalculations() {
    // Hard code something in case the data attr parsing goes belly-up
    let fallbackDisplay = "-";

    try {
      const variant = this.selectedVariant;
      const sku = this.quantityData[variant].sku;

      const quantity = this.currentQuantity;

      const size = this.currentSize;
      const parsedSize = parseVariantSize(size);
      const validSize = !isNullOrBigZero(parsedSize);
      const hasSize = size != undefined;

      const {
        currency,
        extraChargeMultiplier,
        priceMultiplier,
        quantityType,
        sizeExcludedFromCalculation,
        sizeUnitConversionFactor,
        ...other
      } = this.quantityData[variant];

      // We can now get the real fallback display to use
      fallbackDisplay = other.fallbackDisplay;

      // Generate and set the total price
      const opts = {
        currency,
        extraChargeMultiplier,
        locale: getAppConfiguration("locale"),
        priceMultiplier,
        quantity,
        quantityType,
        size,
        sizeExcludedFromCalculation,
        sizeUnitConversionFactor,
      };
      const totalPriceNumber = calculateTotalPriceNumber(opts);
      const totalPrice = calculateTotalPriceForDisplay(opts, totalPriceNumber);

      this.updateRowTotal(totalPrice !== null ? totalPrice : fallbackDisplay);
      //this.updateTotalPriceNumber(totalPriceNumber, size);

      // Update the info sections, if any
      this.extraInfoControllers.forEach((c) =>
        c.updateValue({ quantity, quantityType, size, fallbackDisplay }),
      );

      this.checkMarks(
        totalPriceNumber > 0,
        sku,
        variant,
        this.isCurrentConfigurationValid(hasSize ? validSize : true),
      );

      const detail: ProductPriceChangedDetail = {
        totalPriceNumber,
        sku,
        variant,
        size,
        parsedSize,
        validSize,
        hasSize,
      };

      // When a new estimated price is calculated on this row, we dispatch an event so a parent controller can update any totals.
      this.dispatch("product_price_change", {
        bubbles: true,
        cancelable: false,
        detail,
      });

      if (this.currentSizeField) {
        this.currentSizeField.required =
          hasSize && !validSize && totalPriceNumber > 0;
      }
    } catch (e) {
      // Ru-roh, something's gone wrong when parsing the attributes
      // TODO handle this error: show the user somehow
      console.error("Order guide calc error", e);
      this.updateRowTotal("!!");
    }
  }

  private updateRowTotal(formattedTotal: string) {
    safelySetInnerHTML(
      this.rowTotalPriceTarget,
      markAsSafeHTML(formattedTotal),
    );
  }

  private displaySelectedVariant(variant: string) {
    const toggler = (field: BaseController) => {
      const uid = field.getOptionalDataAttr("variantUid");
      if (uid != undefined) {
        const current = uid == variant;
        field.disabled = !current;
        this.toggleTargetElementClasses(field.element, current, ["hidden"], []);
      }
    };
    this.formFieldControllers.forEach(toggler);
    this.extraInfoControllers.forEach(toggler);
    this.pricePerUomTargets.forEach((target) => {
      if (variant == target.getAttribute("data-variant-uid")) {
        target.classList.remove("hidden");
      } else {
        target.classList.add("hidden");
      }
    });
  }

  private get currentQuantity(): string {
    return this.currentQuantityField!.value;
  }

  private get currentQuantityField() {
    return this.formFieldControllers.find(
      (field) =>
        field.element.id.startsWith("quantity") &&
        field.getOptionalDataAttr("variantUid") == this.selectedVariant,
    );
  }

  private get currentSize(): string | undefined {
    const field = this.currentSizeField;
    return field ? field.value : undefined;
  }

  private get currentSizeField() {
    return this.formFieldControllers.find(
      (field) =>
        field.element.id.startsWith("portion-size") &&
        field.getOptionalDataAttr("variantUid") == this.selectedVariant,
    );
  }

  public hideOnSearch(search: string) {
    const st = search.trim().toLowerCase();
    if (st.length < 2 || st == "") {
      this.element.classList.remove("hidden");
      return;
    }
    const matches =
      this.shortDescriptionTarget.innerText.toLowerCase().includes(st) ||
      this.sku.includes(st);

    this.element.classList.toggle("hidden", !matches);
  }

  public get sku() {
    return this.skuTarget.innerText.toLowerCase();
  }

  public sortableParam(name: string) {
    switch (name) {
      case "sku":
        return this.sku;
      case "description":
        return this.shortDescriptionTarget.innerText;
    }
  }

  private checkMarks(
    hasTotal: boolean,
    sku: string,
    variant: string,
    isValidConfiguration: boolean,
  ) {
    const group = document.getElementById(`order-guide-check-${sku}`);
    // set the visibility to hidden of all the child elements of group
    if (group) {
      for (const child of group.children) {
        (child as HTMLDivElement).style.display = "none";
      }
    }

    const el = document.getElementById(`order-guide-check-${sku}-${variant}`);
    const elWarn = document.getElementById(
      `order-guide-warn-${sku}-${variant}`,
    );
    if (isValidConfiguration) {
      if (el) el.style.display = hasTotal ? "block" : "none";
      if (elWarn) elWarn.style.display = "none";
    } else {
      if (el) el.style.display = "none";
      if (elWarn) elWarn.style.display = "block";
    }
  }
}
